<template>
  <div class="row q-mt-md">
    <div class="col-5">
      <q-toolbar class="bg-primary text-white shadow-2">
        <q-toolbar-title>{{ $t('label.push.unselect_audience') }}</q-toolbar-title>
      </q-toolbar>
      <div class="audience-user">
        <q-table
          bordered
          flat
          dense
          separator="cell"
          title=""
          :rows="audiences"
          row-key="_id"
          v-model:pagination="pagination"
          :rows-per-page-options="rowPerPageOptions"
          :no-data-label="$t('messages.no_data_available')"
          @request="onRequest"
          ref="audienceDataRef"
          hide-header
          :wrap-cells="true"
          class="text-grey-8"
        >
          <template v-slot:body="props">
            <q-tr :props="props" v-if="onDisabledOr(props.row._id) !== true && onDisabledNot(props.row._id) !== true">
              <q-td :props="props.index"
                >{{ props.row.title }}<br />
                <p class="q-item__label q-item__label--caption text-caption">
                  {{ $t('label.audience.audience_size') }}:{{ props.row.audience_size }}
                </p>
              </q-td>
              <q-td style="width: 100px">
                <div class="row q-gutter-sm">
                  <q-btn size="sm" outline round color="primary" @click="onOr(props.row, index)">{{
                    $t('label.push.or')
                  }}</q-btn>
                  <q-btn size="sm" outline round color="deep-orange" @click="onNot(props.row)">{{
                    $t('label.push.not')
                  }}</q-btn>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
    <div class="col-2 q-my-auto">
      <div class="q-pa-md q-gutter-sm q-ma-md text-center">
        <q-btn outline round text-color="grey" size="md" icon="arrow_left" class="q-mr-sm btn-custom" />
        <q-btn outline round text-color="grey" size="md" icon="arrow_right" class="q-mr-sm btn-custom" />
      </div>
    </div>
    <div class="col-5">
      <q-list bordered separator>
        <q-toolbar class="bg-green text-white shadow-2">
          <q-toolbar-title>{{ $t('label.push.selected_audience_or') }}</q-toolbar-title>
        </q-toolbar>
        <q-item clickable v-ripple v-for="(item, index) in value.audience_or" :key="index">
          <q-item-section>
            <q-item-label>{{ item.title }}</q-item-label>
            <q-item-label caption>{{ $t('label.audience.audience_size') }}:{{ item.audience_size }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn no-caps outline :label="$t('label.push.unselect')" @click="onUnSelectedOr(index)" />
          </q-item-section>
        </q-item>
      </q-list>

      <q-list bordered separator class="q-mt-md">
        <q-toolbar class="bg-purple text-white shadow-2">
          <q-toolbar-title>{{ $t('label.push.selected_audience_not') }}</q-toolbar-title>
        </q-toolbar>
        <q-item clickable v-ripple v-for="(item, index) in value.audience_not" :key="index">
          <q-item-section>
            <q-item-label>{{ item.title }}</q-item-label>
            <q-item-label caption>{{ $t('label.audience.audience_size') }}:{{ item.audience_size }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-btn no-caps outline :label="$t('label.push.unselect')" @click="onUnSelectedNot(index)" />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
  <!-- <span v-if="errorMessage !== ''" class="q-pl-sm column">
    <div class="text-red">{{ errorMessage }}</div>
  </span> -->
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { constant } from '@/utils/constants'
import { IAudience, IAudienceOrNot, IPushAudience } from '@/utils/types'
import { ACTION_AUDIENCE } from '@/store/actions'
import PushCampaignMixin from '../mixins/PushCampaignMixin.vue'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:handleLoading'],
})
export default class AudienceOrNotSetting extends mixins(PushCampaignMixin) {
  @Prop({})
  modelValue: IPushAudience = {
    audience_or: [],
    audience_not: [],
  }

  @Prop({})
  isTestMode!: boolean

  // errorMessage = ''
  totalAudienceProd = 0
  allProd!: IAudience

  audiences: IAudience[] = []
  pagination = {
    sortBy: '',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get pushs() {
    return this.selectedPush
  }

  get value() {
    return this.modelValue
  }

  set value(value: IPushAudience) {
    this.$emit('update:modelValue', value)
  }

  async getAudienceOr() {
    if (this.value.audience_or) {
      for (let index = 0; index < this.value.audience_or.length; index++) {
        const element = this.value.audience_or[index]
        if (element._id === constant.DEFAULT_ALL_USER_AUDIENCE_ID) {
          if (this.allProd) {
            element.title = this.allProd.title
            element.audience_size = this.allProd.audience_size
          }
        } else {
          let result = this.audiences.find((or) => or._id === element._id)
          if (!result) {
            result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_ITEM, element._id)
          }
          if (result) {
            element.title = result.title
            element.audience_size = result.audience_size
          }
        }
      }
    }
  }

  async getAudienceNot() {
    this.audienceNot = []
    if (this.value.audience_not) {
      for (let index = 0; index < this.value.audience_not.length; index++) {
        const element = this.value.audience_not[index]
        if (element._id === constant.DEFAULT_ALL_USER_AUDIENCE_ID) {
          if (this.allProd) {
            element.title = this.allProd.title
            element.audience_size = this.allProd.audience_size
          }
        } else {
          let result = this.audiences.find((not) => not._id === element._id)
          if (!result) {
            result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_ITEM, element._id)
          }

          if (result) {
            element.title = result.title
            element.audience_size = result.audience_size
          }
        }
      }
    }
  }

  @Watch('value', { deep: true })
  async loadAudienceOrandNot() {
    // console.log('loadAudienceOrandNot', this.value)
    // console.log('loadAudienceOrandNot allProd', this.allProd)
    await this.getAudienceOr()
    await this.getAudienceNot()
  }

  onDisabledOr(id: string) {
    const checkExist = this.value?.audience_or?.find((or) => or._id === id)
    if (checkExist) {
      return true
    }
  }

  onDisabledNot(id: string) {
    const checkExist = this.value?.audience_not?.find((or) => or._id === id)
    if (checkExist) {
      return true
    }
  }

  async onOr(row: IAudience) {
    if (this.value.audience_or) {
      this.value.audience_or.push(row)
    }
  }

  async onNot(row: IAudience) {
    if (this.value.audience_not) {
      this.value.audience_not.push(row)
    }
  }

  async onUnSelectedOr(index: number) {
    if (this.value.audience_or) {
      this.value.audience_or.splice(index, 1)
    }
  }

  async onUnSelectedNot(index: number) {
    if (this.value.audience_not) {
      this.value.audience_not.splice(index, 1)
    }
  }

  async onRequest(props) {
    this.audiences = []
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.$emit('update:handleLoading', true)
    const fetchCount = rowsPerPage === 0 ? this.totalAudienceProd : rowsPerPage
    const startRow = (page - 1) * rowsPerPage
    const returnedData: [] = await this.fetchFromServer(
      startRow,
      fetchCount,
      filter,
      sortBy,
      descending,
      this.isTestMode
    )
    if (page === 1) {
      this.testAudiences = []
      if (this.allProd && this.allProd.audience_size && this.allProd.audience_size > 0) {
        this.audiences.push(this.allProd)
      }

      this.audiences = this.audiences.concat(returnedData)
    } else {
      this.audiences.splice(0, this.audiences.length, ...returnedData)
    }

    this.pagination.rowsNumber = this.totalAudienceProd
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending
    this.$emit('update:handleLoading', false)
  }

  async fetchFromServer(startRow, count, filter, sortBy, descending, isTestMode) {
    const items = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_SIMPLE_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: isTestMode,
      is_include_data: false,
      filter: {
        start_row: startRow,
        rows_number: count,
        filter: filter,
        sort_by: sortBy,
        descending: descending,
      },
    })
    console.log('fetchFromServer', items)
    return items
  }

  async mounted() {
    this.$emit('update:handleLoading', true)
    this.audiences = []
    const result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_USER_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
    })
    // eslint-disable-next-line
    if (result && result['count_active_users'] > 0) {
      this.allProd = {
        _id: constant.DEFAULT_ALL_USER_AUDIENCE_ID,
        app_id: this.selectedAppId,
        title: this.$t('label.all_user_audience_id'),
        // eslint-disable-next-line
        audience_size: result['count_active_users'],
        filter_condition: {
          answers: {},
          date_range: {
            from: '',
            to: '',
          },
        },
      }
      // eslint-disable-next-line
      this.totalAudienceProd = result['total_audiences']
    }
    // get initial data from server (1st page)
    await this.$refs.audienceDataRef.requestServerInteraction()
  }
}
</script>
<style lang="scss" scoped>
</style>
